export const airbnbLinkRegex =
    /^(?:https?:\/\/)?(?:www.)?airbnb.[a-zA-Z]{2,5}(?:.[a-zA-Z]{2,5})?\/users\/show\/([0-9]{1,20})/;
export const linkedInLinkRegex =
    /^(?:https?:\/\/)?(?:www.)?linkedin.[a-zA-Z]{2,5}(?:.[a-zA-Z]{2,5})?\/(?:in\/)?([A-Za-zÀ-ÿ0-9\-.%_]{1,50})/;
export const instagramRegex =
    /^(?:https?:\/\/)?(?:www.)?instagram.[a-zA-Z]{2,5}(?:.[a-zA-Z]{2,5})?\/([A-Za-zÀ-ÿ0-9\-.%_]{1,30})/;
export const facebookRegex =
    /^(?:https?:\/\/)?(?:www.)?facebook.[a-zA-Z]{2,5}(?:.[a-zA-Z]{2,5})?\/([A-Za-zÀ-ÿ0-9\-.%_]{1,50})/;

export const airbnbLinkToSlug = (link) => {
    const regex = airbnbLinkRegex.exec(link);
    if (!regex) {
        return null;
    }
    return regex[1];
};

export const airbnbSlugToURL = (slug) => {
    if (!slug) {
        return null;
    }
    return `https://www.airbnb.com/users/show/${slug}`;
};

export const linkedInLinkToSlug = (link) => {
    const regex = linkedInLinkRegex.exec(link);
    if (!regex) {
        return null;
    }
    return regex[1];
};

export const linkedInSlugToURL = (slug) => {
    if (!slug) {
        return null;
    }
    return `https://www.linkedin.com/in/${slug}/`;
};

export const instagramLinkToSlug = (link) => {
    const regex = instagramRegex.exec(link);
    if (!regex) {
        return null;
    }
    return regex[1];
};

export const instagramSlugToURL = (slug) => {
    if (!slug) {
        return null;
    }
    return `https://www.instagram.com/${slug}/`;
};

export const facebookLinkToSlug = (link) => {
    const regex = facebookRegex.exec(link);
    if (!regex) {
        return null;
    }
    return regex[1];
};

export const facebookSlugToURL = (slug) => {
    if (!slug) {
        return null;
    }
    return `https://www.facebook.com/${slug}/`;
};
